import { NextButton, PreviousButton } from "./Buttons";
import ThankYou from "./ThankYou";

function OtherBuy() {
  return (
    <>
      <fieldset>
        <p> Send your coin to our wallet </p>
        <img alt=" " src="../img/cashbase_QR.png" className="col-6 img-fluid" />
        <p className="col-6">
          Call or send message us a message that include your full name, bank name, account number, phone number
        </p>
        <PreviousButton />
        <NextButton />
      </fieldset>
      <ThankYou />
    </>
  )
}

export default OtherBuy
