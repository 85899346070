import { useState } from "react";

import BinanceBuy from "./BinanceBuy";
import BinanceSell from "./BinanceSell";
import { NextButton } from "./Buttons";
import OtherBuy from "./OtherBuy";
import ProgressBar from "./ProgressBar";

const flow = (merchant, coin, transaction) => {
  if (merchant === "Binance") {
    return transaction === "Buy" ? <BinanceBuy coin={coin} /> : <BinanceSell coin={coin} />
  }
  return <OtherBuy coin={coin} />
}

function DealForm() {
  const [merchant, setMerchant] = useState("Binance");
  const changeMerchant = (e) => setMerchant(e.target.value);
  const [coin, setCoin] = useState("USDT");
  const changeCoin = (e) => setCoin(e.target.value);
  const [transaction, setTransaction] = useState("Sell");
  const changeTransaction = (e) => setTransaction(e.target.value)
  return (
    <>
      <ProgressBar steps={transaction === "Buy" ? 4 : 3} />
      <fieldset>
        <p>Select preffered transaction</p>
        <form>
          <div className="form-row">
            <div className="form-group col-6 mt-2">
              <label for="merchants">Merchant:</label>
              <select className="form-control " name="merchant" onChange={e => changeMerchant(e)}>
                <option>Binance</option>
                <option disabled>Others</option>
              </select>
            </div>
            <div className="form-group col-6 mt-2">
              <label for="asset">Asset:</label>
              <select className="form-control" name="coin" onChange={e => changeCoin(e)} >
                <option>USDT</option>
                <option>DAI</option>
                <option>BUSD</option>
                {transaction === "Sell" ?
                  <>
                    <option>NGN</option>
                    <option>ETH</option>
                    <option>BTC</option>
                    <option>BNB</option>
                  </> : ""
                }
              </select>
            </div>
          </div>
          
          <label for="mode">Mode:</label>
          <select className="form-control col-12 " name="transaction" onChange={e => changeTransaction(e)} >
            <option value="Sell" >We pay first, you release later</option>
            <option value="Buy" disabled>You send us your coin first, we pay within 20mins</option>
          </select>
        </form>
        <NextButton />
      </fieldset>
      {flow(merchant, coin, transaction)}
    </>
  )
}

export default DealForm
