import Interactions from "./components/Interactions";
import TopNav from "./components/TopNav";

function App() {
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="mx-auto">
          <div className="pb-4" style={{ width: "100%", maxWidth: "800px" }}>
            <TopNav />
            <Interactions />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
