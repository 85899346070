function TopNav() {
  return (
    <>
    <nav class="navbar fixed-top navbar-light bg-light">
      <a class="navbar-brand mx-auto" href="/">Cashbase</a>
    </nav>
      <div className="text-center">
        <div className="">
          <p className="card-text mt-4 pt-5">
            We accept<br />
            <span className="badge badge-pill badge-primary">USDT</span>
            <span className="badge badge-pill badge-primary">BUSD</span>
            <span className="badge badge-pill badge-primary">DAI</span>
            <span className="badge badge-pill badge-primary">BNB</span>
            <span className="badge badge-pill badge-primary">BTC</span>
            <span className="badge badge-pill badge-primary">NGN</span>
            <span className="badge badge-pill badge-primary">USD</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default TopNav
