import DealForm from "./DealForm";

function Interactions() {
  return (
    <div className="col-12 mt-2 text-center">
      <DealForm />
    </div>
  );
}

export default Interactions;
