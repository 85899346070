import { PreviousButton } from "./Buttons";

function ThankYou() {
  return (
    <fieldset>
      <p>
        Done
        <small>We will contact you shortly</small>
      </p>
      <img alt=" " src="../img/thank_you.png"  className="img-fluid" />
      <br />
      <PreviousButton />
    </fieldset>
  )
}
export default ThankYou
