import { NextButton, PreviousButton, DownloadQRBtn } from "./Buttons";
import ThankYou from "./ThankYou";


function BinanceSell({ coin }) {
  const qrPath = `../img/QRCodes/Sell${coin}.jpg`;
  return (
    <>
      <fieldset>
        <p>Scan our QR Code</p>
        <div className="row">
          <div className="col-6 my-auto">
              <div class="d-flex justify-content-center card-text">
                <DownloadQRBtn imgPath={qrPath} />
              </div>
              <img alt=" " src={qrPath} className="card-img-bottom img-fluid mt-2" />
          </div>
          <figure class="figure col-6 my-auto">
            <figcaption class="figure-caption text-center">How to scan on phone
            </figcaption>
            <img alt="Scan using binance mobile app" src="../img/PhoneQRScan.gif" className="figure-img img-fluid rounded" />
          </figure>
        </div>
        <PreviousButton />
        <NextButton />
      </fieldset>
      <ThankYou />
    </>
  )
}

export default BinanceSell
