function ProgressBar({steps}) {
  var progressList = [];
  let stepNums = () => {
      for (var i = 0; i < steps; i++) {
        progressList.push(<li key={i} className={`col-${12/steps} ${i===0 ? "active" : ""}`}> Step {i} </li>);
      }
      return progressList
  }
  return (
      <ul id="progressbar" className="list-group list-group-horizontal mt-5 text-center">
            {stepNums()}
      </ul>
  )
}

export default ProgressBar
