import { NextButton, PreviousButton } from "./Buttons";
import ThankYou from "./ThankYou";


function BinanceBuy({coin}) {
  return (
    <>
      <fieldset>
        <p>Scan our QR Code</p>
        <img alt=" " src={`../img/QRCodes/Pay${coin}.jpg`} className="col-6 img-fluid" />
        <img alt=" " src="../img/cashbase_QR.png"  className="col-6 img-fluid" />
        <input type="button" name="previous" className="previous action-button-previous" value="Previous" />
        <input type="button" name="next" className="next action-button" value="Next" />
      </fieldset>
      <fieldset>
        <p>
          Fill the details as shown
          <small>Note: in the narration field, include your full name, bank name, account number, phone number</small>
        </p>
        <img alt=" " src="../img/cashbase_QR.png" className="col-12 img-fluid" />
        <PreviousButton />
        <NextButton />
      </fieldset>
      <ThankYou />
    </>
  )
}

export default BinanceBuy
