export const NextButton = () => (
        <input type="button" name="next" className="next action-button my-3" value="Next" />
    )

export const PreviousButton = () => (
        <input type="button" name="previous" className="previous action-button action-button-previous my-3" value="Previous" />
    )

export const DownloadQRBtn = ({imgPath}) => (
        <a href={imgPath} download className="action-button">Download</a>
    )
